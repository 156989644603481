import React, {useState, useContext, useEffect, useRef} from "react"

import {Grid, Collapse, Box, Typography} from "@mui/material"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import CheckTable from "../../components/attendance/checkTable"
import AccessCheck from "../../components/attendance/accessCheck" // 權限要討論 mido
import Layout from "../../layouts"
import AddIcCard from "../../components/attendance/addIcCard"

import {CfpSearch} from "../../components/cfp/cfpSearch"

import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import {CfpDropdown, DownloadList} from "../../components/cfp/cfpDropdown"
import {gUtils} from "../../components/utils/gutils"
import {useDialog} from "../../components/utils/cfputils";
import {CfpMsg} from "../../components/cfp/cfpMsg";
import {Link} from "gatsby"
import {gConfig} from "../../chatai/utils/config";

export default function Cards({location}) {
    const {
        gIotCfp,
        gCfpCards,
        gCfp,
        gMemberDevice,
        setCustomModalOpen,
        setModalContent,
        gCompanyEmployee,
        setModalTitle,
        gStripe
    } =
        useContext(GlobalStateContext)
    const [open, setOpen] = useState(false)
    const [chooseOpen, setChooseOpen] = useState(false)
    const [openDropDown, setOpenDropDown] = useState(false)
    const [listDown, setListDown] = useState([])
    const [touchList, setTouchList] = useState([])
    const [tbData, setTbData] = useState([])
    const [refreshTb, setRefreshTb] = useState(0x111)
    const [tbSelect, setTbSelect] = useState([])
    const [dropdownPosition, setDropdownPosition] = useState({left: 0, top: 0})
    const setIsOpenDialog = useDialog()
    const ref = useRef(null)
    const [isBlurred, setIsBlurred] = useState(false) // 添加頁面過濾狀態
    const [listTempMembers,setListTempMembers]=useState([])
    const [putCardList,setPutCardList]=useState([])





    useEffect(() => {

        console.log("收到临时卡片数据",gCfpCards.temDeviceCards,touchList,gCfpCards.allCards)

        if (gCfpCards.temDeviceCards.length>0&&touchList.length>0){
                 let  devices=touchList.map(item=>item.deviceUUID)
            gCfpCards.temDeviceCards.map(item=>{
                   let   findItem=gCfpCards.allCards.find(it=>it.cardID===item.cardID)
                   if (findItem){
                       devices.forEach(uuid=>{

                          if (!findItem.uuids.includes(uuid)){
                               console.log("收到临时卡片数据-添加",uuid,item,putCardList)
                              if (!putCardList.some(cardItem=>cardItem.deviceID===uuid&&item.cardID===cardItem.cardID)){
                                  setPutCardList(prevState => [...prevState,{deviceID:uuid,cardID:item.cardID,cardType:item.cardType,name:item.name}])
                                  gIotCfp.sendCmd(uuid, gConfig.cmdCode.cardPut, gUtils.toCardInfo(item.cardID, item.cardType, item.name))

                              }


                           }
                       })
                   }

              })

        }
        if (gCfpCards.temDeviceCards.length>0&&listTempMembers.length>0){
            listTempMembers.map(item=>{
                if (gCfpCards.temDeviceCards.some(device=>device.cardID===item.cardID)){
                    gCfpCards.updateCards(item)
                }
            })
        }


    }, [gCfpCards.temDeviceCards,listTempMembers])
    useEffect(() => {
        let result = gCfpCards.allCards
        result = result.map(item => {
            if (item.memberID) {
                item.groupName = gMemberDevice.list.reduce((groupName, device) => {
                    if (device.uuids.some(i => i === item.memberID)) {
                        groupName.push(device.name);
                    }
                    return groupName;
                }, []);
            }

            return item

        })
        setTbData(result)
    }, [gCfpCards.allCards, gMemberDevice.list])


    useEffect(() => {

        gCfpCards.setTemDeviceCards([])

        setTbData(gCfpCards.allCards)
        gIotCfp.subIotDevices()
        return () => {
            gIotCfp.cancelSubscriptions()
            setIsOpenDialog(false)
            gCfpCards.setTemDeviceCards([])
            touchList.forEach((item) => {
                gIotCfp.sendCmd(item.deviceUUID, gConfig.cmdCode.cardSetFinish)
            })
        }
    }, [])

    useEffect(() => {
        if (
            gStripe &&
            gStripe.levelInfo &&
            (gStripe.levelInfo.level === 0 && !gStripe.customer.isRootUser)
        ) {
            setIsBlurred(false)
        } else {
            setIsBlurred(false)
        }
    }, [gStripe])

    const handleCheck = async (items) => {
        console.log("handleCheck", tbSelect, items)
        setCustomModalOpen(false);
        setRefreshTb(prevState => prevState + 1)
        let list=[]
        let devices=items.map(item=>item.deviceUUID)
        tbSelect.forEach(item=>{
            devices.forEach(uuid=>{
                if (!item.uuids.includes(uuid)){
                    list.push({deviceID:uuid,cardID:item.cardID,cardType:item.cardType,name:item.name})
                }
            })
        })
        if (list.length>0){
            setIsOpenDialog(true)
            for (let i = 0; i < list.length; i++) {
                let  item=list[i]
                await gIotCfp.sendCmd(item.deviceID, gConfig.cmdCode.cardPut, gUtils.toCardInfo(item.cardID, item.cardType, item.name))
                await delay(1800);

            }
            setIsOpenDialog(false)
        }


    };



    const checkTouckListIsExistDevice = ( call) => {
        console.log("checkTouckListIsExistDevice", touchList.length)
        if (touchList.length > 0) {
            if (call) call(touchList)
        } else {
            setChooseOpen(true)

        }
    }

    const showError = async () => {
        setModalTitle('')
        setModalContent(
            <CfpMsg msg={'有効なカード名およびIDを入力してください。いずれも空欄では登録できません。'}
                    onClick={() => {
                        setCustomModalOpen(false)
                    }}

            />
        )
        setCustomModalOpen(true)
    }
    const handleOpenModalCsv =( call) => {
        let listDevices = gCfp.cfpDevices.filter(item => item.wifiState)
        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                selectableRows={"multiple"}
                setOpenModal={setCustomModalOpen}
                handleClose={() => {
                    setCustomModalOpen(false)
                }}
                data={listDevices}
                handleCheck={(devices) => {
                    setCustomModalOpen(false)
                    if (call) call(devices)

                }}
                location={location}
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }

    const handleOpenModalChoose = (items, call) => {
        let list = []
        items.forEach(item => {
            item.uuids.forEach(uuid => {
                if (!list.includes(uuid)) {
                    list.push(uuid)
                }
            })
        })
        let listDevices = gCfp.cfpDevices.filter(item => (list.includes(item.deviceUUID) && item.wifiState))
        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                selectableRows={"multiple"}
                setOpenModal={setCustomModalOpen}
                handleClose={() => {
                    setCustomModalOpen(false)
                }}
                data={listDevices}
                handleCheck={(devices) => {
                    setCustomModalOpen(false)
                    if (call) call(devices)

                }}
                location={location}
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }
    const  bindOpenModalChoose = () => {
        let listDevices = gCfp.cfpDevices.filter(item => item.wifiState)

        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                selectableRows={"multiple"}
                setOpenModal={setCustomModalOpen}
                handleClose={() => {
                    setCustomModalOpen(false)
                }}
                data={listDevices}
                handleCheck={handleCheck}
                location={location}
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return (
        // <Layout location={location}>www</Layout>
        <Layout location={location}>
            <AccessCheck access="デバイス">
                <Box sx={{display: "flex", flexDirection: "column", position: "relative"}}>
                    {isBlurred && (
                        <React.Fragment>
                            <Box sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(255, 255, 255, 0.5)", // 半透明の背景色を追加
                                zIndex: 10,
                            }}/>
                            <Box
                                sx={{
                                    width: '500px',
                                    height: '150px',
                                    p: 4,
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 20,
                                    // background: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                カード管理のページを閲覧するには、設定ページにて<br/>Lightプラン以上へのアップグレードが必要です。<br/>（Lightプラン以上では今後Hub3との併用で利用可能）
                                <Typography
                                    component={Link}
                                    to="/settings"
                                    color="#000000"
                                    sx={{textDecoration: 'underline', fontWeight: 'bold', paddingTop: "10px"}}
                                >
                                    設定ページはこちら
                                </Typography>
                            </Box>
                        </React.Fragment>
                    )}
                    <Box sx={{filter: isBlurred ? 'blur(5px)' : 'none', pointerEvents: isBlurred ? 'none' : 'auto'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CfpSearch
                                    callSearch={(e) => {
                                        if (e) {
                                            setTbData(
                                                gUtils.findSearchList(
                                                    e,
                                                    gCfpCards.allCards,

                                                    gCfp.cfpDevices
                                                )
                                            )
                                        } else {
                                            setTbData(gCfpCards.allCards)
                                        }
                                    }}
                                />
                                <Collapse in={open}>
                                    <AddIcCard
                                        mdatas={gCfpCards.temDeviceCards}
                                        setOpen={setOpen}
                                        open={open}
                                        setTouchList={setTouchList}
                                        touchList={touchList}
                                        downData={tbData}
                                        setCsvData={(data) => {
                                            console.log("添加卡片数据", data)
                                            handleOpenModalCsv(async (devices)=>{
                                                gCfpCards.setTemDeviceCards([])
                                                let list = []
                                                let isError = false
                                                data.forEach((item) => {
                                                    if (item.id || item.ID || item.cardID) {
                                                      let  obj={
                                                          cardID: item.id || item.ID || item.cardID,
                                                          name :item.カード名 || item.name || '',
                                                          cardType: item.cardType || "02",
                                                      }
                                                        if (item.ユーザー) {
                                                            let member = gCompanyEmployee.employees.Items.find(it => it.employeeName === item.ユーザー)
                                                            if (member){
                                                                obj.memberID=member.subUUID
                                                                setListTempMembers(prevState => [...prevState,obj])
                                                            }
                                                        }
                                                        if (obj.cardID){
                                                            list.push(obj)
                                                        }
                                                    } else {
                                                        isError = true
                                                    }

                                                })
                                                console.log("list数据",list,devices)
                                                if (isError) {
                                                    await showError()
                                                } else {
                                                    if (list.length > 0) {
                                                        setIsOpenDialog(true)

                                                        try {
                                                            for (let i = 0; i < devices.length; i++) {
                                                                let deviceID = devices[i].deviceUUID;
                                                                for (let k = 0; k < list.length; k++) {
                                                                    if (list[k] && list[k].cardID) {
                                                                        await gIotCfp.sendCmd(deviceID, gConfig.cmdCode.cardPut, gUtils.toCardInfo(list[k].cardID, list[k].cardType, list[k].name));
                                                                        await delay(1800);
                                                                    }
                                                                }
                                                            }
                                                        } finally {
                                                            setIsOpenDialog(false);
                                                        }
                                                    }
                                                }


                                            })


                                        }}
                                        chooseOpenHold={chooseOpen}
                                        setChooseOpenHold={setChooseOpen}
                                        location={location}
                                        goSet={(isChoose) => {
                                            checkTouckListIsExistDevice( (list) => {


                                                list.forEach((item) => {
                                                    gIotCfp.sendCmd(item.deviceUUID, isChoose ? gConfig.cmdCode.cardSet : gConfig.cmdCode.cardSetFinish)
                                                })
                                            })


                                        }}

                                        addCard={(d) => {


                                            gCfpCards.updateCards(d)

                                        }}
                                    />
                                </Collapse>

                                <Cfptb
                                    callAdd={() => {
                                        setOpen(true)
                                    }}
                                    isAdd={!open}
                                    data={tbData}
                                    path={"/cards/carddetails"}
                                    text={"カード一覧"}
                                    columns={cfpColumns.cardColumns({
                                        gCompanyEmployee: gCompanyEmployee,
                                        click: (isGroup, value, e) => {
                                            const rect = e.currentTarget.getBoundingClientRect()
                                            setDropdownPosition({left: rect.left, top: rect.top})
                                            setOpenDropDown(true)

                                            if (!isGroup) {

                                                setListDown(gUtils.uuidsToNames(value, gCfp.cfpDevices))
                                            } else {
                                                setListDown(value)
                                            }

                                        },
                                        ref: ref,
                                        listDevices: gCfp.cfpDevices

                                    })}
                                    callDelData={(items) => {
                                       handleOpenModalChoose(items, async (list) => {
                                            let devices = list.map(item => item.deviceUUID)
                                            setIsOpenDialog(true)
                                            for (let i = 0; i < items.length; i++) {
                                                let uuids = items[i].uuids
                                                for (let k = 0; k < uuids.length; k++) {
                                                    if (devices.includes(uuids[k])) {
                                                        await gIotCfp.sendCmd(uuids[k], gConfig.cmdCode.cardDelete, gUtils.toCardInfo(items[i].cardID, items[i].cardType, items[i].name))
                                                    }
                                                    await delay(1800);
                                                }
                                            }
                                            setIsOpenDialog(false)
                                        })

                                    }}
                                    callBind={bindOpenModalChoose}
                                    refreshTb={refreshTb}
                                    callSelects={(lists) => {
                                        setTbSelect(lists)
                                    }}
                                />
                            </Grid>
                            <CfpDropdown
                                open={openDropDown}
                                closeOpen={() => {
                                    setOpenDropDown(false)
                                }}
                                position={dropdownPosition}
                                viewRef={ref}
                            >
                                <DownloadList
                                    list={listDown}
                                />
                            </CfpDropdown>
                        </Grid>
                    </Box>
                </Box>
            </AccessCheck>
        </Layout>
    )
}
