import React, {useContext, useState, useEffect} from "react"

import {GlobalStateContext} from "../../context/GlobalContextProvider"
import CheckTable from "./checkTable"

import {
    Card,
    CardHeader,
    IconButton,
    Box,
    Typography,

    CardContent,
} from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import Hider from "../utils/Hider"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import Cfpuploaddata from "../cfp/cfpuploaddata"
import CSVHandler from "../cfp/csvHandler"
import CfpSimpleTable from "../cfp/cfpsimpletable";

export default function AddIcCard({
                                      mdatas,
                                      open,
                                      setOpen,
                                      goSet,

                                      addCard,
                                      downData,
                                      setCsvData,
                                      touchList,
                                      setTouchList,
                                      isShowFileClick,
                                      chooseOpenHold,
                                      isUploadCsvCall,
    setChooseOpenHold
                                  }) {
    const {gCfp, setCustomModalOpen, setModalContent} =
        useContext(GlobalStateContext)

    const [readCardContent, setReadCardContent] = useState(false)

    const [list, setList] = useState([])

    useEffect(() => {
        setList(touchList)
    }, [touchList]);

    useEffect(()=>{
        if (chooseOpenHold){
            handleOpenModal()

        }else {
            setCustomModalOpen(false)
        }

    },[chooseOpenHold])



    const readCardClick = () => {
        // setReadCard("新しいメッセージ")

        let nIschoose = !readCardContent

        setReadCardContent(nIschoose)
        if (goSet) {
            goSet(nIschoose)
        }
    }

    const handleOpenModal = () => {


        let listDevices = gCfp.cfpDevices.filter(item => item.wifiState&&!touchList.some(obj=>obj.deviceUUID===item.deviceUUID))

        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                selectableRows={"multiple"}
               // selectableRows={'single'}
                data={listDevices} //資料
                setOpenModal={setCustomModalOpen} //開關Modal的屬性
                handleCheck={handleCheck} //勾選項目後要做的處理function
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }

    // 勾選項目後要做的處理function要寫這裡
    const handleCheck = (selectedItems) => {
        setCustomModalOpen(false)
        setChooseOpenHold(false)



        setTouchList([...selectedItems,...touchList])


    }

    return (
        <Card sx={{mb: "15px"}}>
            <CardHeader
                title={
                    <Box
                        sx={{
                            display: "flex",
                            mt: "20px",
                            mb: "20px",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h2" sx={{ml: "9px"}}>
                            新規カード追加
                        </Typography>

                        <Box>
                            <Box sx={{display: "flex"}}>

                                <CSVHandler setData={setCsvData}
                                            isShowFileClick={isShowFileClick}
                                            downData={downData}
                                            isUserData={false} isUploadCsv={list.length>0} isUploadCsvCall={ isUploadCsvCall} />


                                <IconButton
                                    onClick={() => {
                                        setOpen(!open)
                                    }}
                                    sx={{"&:hover": {color: "primary.main"}}}
                                >
                                    <Hider show={open}>
                                        <KeyboardArrowUpIcon/>
                                        <KeyboardArrowDownIcon/>
                                    </Hider>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                }
            />
            <CardContent>

                <Box sx={{mt: "16px"}}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography variant="h3" sx={{ml: "9px"}}>
                            1.認証機器を選択
                        </Typography>
                        <IconButton
                            onClick={() => {
                                handleOpenModal()
                            }}
                        >
                            <AddCircleIcon style={{color: "#28AEB1"}}/>
                        </IconButton>
                    </Box>
                    <Hider show={list.length>0}>

                        <CfpSimpleTable
                            items={list}
                            btnDel={(item) => {

                                setTouchList((prevState) =>
                                    prevState.filter(
                                        (obj) => obj.deviceUUID !== item.deviceUUID
                                    )
                                )
                            }}
                            name={"deviceName"}
                        />

                    </Hider>
                </Box>
                <Box sx={{mt: "30px"}}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography variant="h3" sx={{ml: "9px"}}>
                            2.カード読み取り
                        </Typography>
                        <IconButton
                            onClick={readCardClick}
                            size="small"
                            variant="outlined"
                            sx={{
                                color: "#28AEB1",
                            }}
                        >
                            {!readCardContent ? (
                                <AddCircleIcon style={{color: "#28AEB1"}}/>
                            ) : (
                                <RemoveCircleIcon style={{color: "#28AEB1"}}/>
                            )}
                        </IconButton>
                    </Box>
                    <Cfpuploaddata
                        b2t={"＋ボタンを押しタッチ/タッチプロにカードをかざして下さい。"}
                        addCard={addCard}
                        cards={mdatas}
                        readCardContent={readCardContent}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}
